import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import HeadHelmet from "../components/HeadHelmet/HeadHelmet";
import ReactPlayer from "react-player";

import imgFeatured from "../assets/image/snorefreeswiss/bite-snoring-custom-magnet.jpg";
import imgByteWithout from "../assets/image/snorefreeswiss/bite-snoring-custom-magnet-without.jpg";
import imgByteWith from "../assets/image/snorefreeswiss/bite-snoring-custom-magnet-with.jpg";

const youTubeVideoHowItWorks = 'https://www.youtube.com/watch?v=07ol_k9UVFw' // How to apply an anti-snoring
const youTubeVideoBiteRotating = 'https://www.youtube.com/watch?v=U3ZYsLjT0GI' // Rotating Bite - Maßgefertigte Schnarch-Schiene


const AntiSnoreExplained = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "null", // cta, account, null
        }}
      >
        <HeadHelmet
          title='snore_bite_title'
          description='snore_bite_s2_b'
          image={imgFeatured}
        />

        <div className="inner-banner pt-20 pb-8">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h1 className="title gr-text-2 mb-9 heading-color">
                    {t('snore_bite_title')}
                  </h1>
                </div>
              </Col>
              <Col xs="10">
                <div className="pt-lg-9 pb-8">
                  <img src={imgFeatured} alt={ t('snore_bite_title') } className="w-100" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <h2 className="gr-text-8 fw-bold pb-3 heading-color">{t('snore_bite_s1_t')}</h2>
                    <p className="gr-text-9 text-color-opacity">
                      {t('snore_bite_s1_b')} </p>
                  </div>
                  <div className="single-block mb-11">
                    <h2 className="gr-text-8 fw-bold pb-3 heading-color">{t('snore_bite_s2_t')}</h2>
                    <p className="gr-text-9 text-color-opacity">
                      {t('snore_bite_s2_b')} </p>
                  </div>
                  <div className="animation-item mb-10 mt-10">
                    <img
                      className="main-img w-100 rounded-8"
                      src={imgByteWithout}
                      width={400}
                      alt="Customized Anti-Snoring Solution Bite"
                      id="an-item-2p1"
                    />
                  </div>
                  <div className="animation-item mb-10 mt-10">
                    <img
                      className="main-img w-100 rounded-8"
                      src={imgByteWith}
                      width={400}
                      alt="Customized Anti-Snoring Solution Bite"
                      id="an-item-2p1"
                    />
                  </div>


                  <div className="single-block mb-11">
                    <h2 className="gr-text-8 fw-bold pb-3 heading-color">{t('snore_bite_s3_t')}</h2>
                    <p className="gr-text-9 text-color-opacity">
                      {t('snore_bite_s3_b')} </p>
                    <ul className="list-unstyled">
                      {[1, 2, 3, 4, 5].map((item, index) => (
                      <li className="mt-7 text-color-opacity">
                        <i
                          className="rounded-circle bg-dark d-inline-block me-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        {t('snore_bite_s3_b_l'+(index+1))}
                      </li>))
                      }
                    </ul>
                  </div>
                  <div className="single-block mb-11">
                    {/* <h2 className="gr-text-8 fw-bold pb-3 heading-color">{t('snore_bite_s2_t')}</h2> */}
                    <p className="gr-text-9 text-color-opacity">
                      {t('snore_bite_s4_b')} </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="content-video-thumb rounded-8 mt-10 mb-10">
                  <div className={`h-100 d-flex align-items-center w-100`}>
                    {/* <DivStyled 
                  // className={`${loading ? "loading" : "loaded"}`}
                  >  */}
                    <ReactPlayer
                      url={youTubeVideoBiteRotating}
                      config={{
                        youtube: {
                          playerVars: { showinfo: 0, controls: 1, rel: 0, modestbranding: 1 }
                        }
                      }}
                      
                    // width="100%"
                    // height="100%"
                    // controls
                    // onReady={() => {
                    //   setLoading(false);
                    // }}
                    />
                    {/* </DivStyled> */}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="content-video-thumb rounded-8 mt-10 mb-10">
                  <div className={`h-100 d-flex align-items-center w-100`}>
                    {/* <DivStyled 
                  // className={`${loading ? "loading" : "loaded"}`}
                  >  */}
                    <ReactPlayer
                      url={youTubeVideoHowItWorks}
                      config={{
                        youtube: {
                          playerVars: { showinfo: 0, controls: 1, rel: 0, modestbranding: 1 }
                        }
                      }}

                    // width="100%"
                    // height="100%"
                    // controls
                    // onReady={() => {
                    //   setLoading(false);
                    // }}
                    />
                    {/* </DivStyled> */}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="10">
                <div className="px-md-15 text-center">
                  <Link
                    to="/contact"
                    className="btn btn-blue text-white gr-hover-y px-lg-9"
                  >
                    {t('cta_cta')}&nbsp;<i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </Col>

            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default AntiSnoreExplained;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
